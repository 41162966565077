import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import QuoteButton from '../QuoteButton/QuoteButton'
import './MainContent.css'
import { ReactTyped } from 'react-typed'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faStore, faTruck, faHome, faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import { brandLogos, reviews, detailedBrandsByCategory } from '../../utils/productsData'
import MiniBanner from '../MiniBanner/MiniBanner'
import _ from 'lodash'

function MainContent() {
  const [searchTerm, setSearchTerm] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const inputRef = useRef(null)
  const navigate = useNavigate()

  // Memoizar el filtrado de items para evitar recálculos en cada renderizado
  const filteredItems = useMemo(() => {
    const searchTerms = searchTerm.toLowerCase().replace(/\s+/g, ' ').trim().split(' ')
    return Object.values(detailedBrandsByCategory)
      .flatMap(category => {
        const matchingBrands = Object.entries(category.brands).flatMap(([brand, models]) => {
          const matchingModels = models
            .filter(model => searchTerms.every(term => model.toLowerCase().includes(term)))
            .map(model => ({ category: category.name, brand, model }))
          if (searchTerms.every(term => brand.toLowerCase().includes(term)) || matchingModels.length > 0) {
            return matchingModels.length > 0
              ? matchingModels
              : [{ category: category.name, brand, model: '' }]
          }
          return []
        })
        if (matchingBrands.length === 0 && searchTerms.every(term => category.name.toLowerCase().includes(term))) {
          return Object.keys(category.brands).map(brand => ({
            category: category.name,
            brand,
            model: ''
          }))
        }
        return matchingBrands.length > 0 ? matchingBrands : []
      })
      .slice(0, 6)
  }, [searchTerm])

  const handleSearchChange = (event) => {
    const inputKeywords = event.target.value
    setSearchTerm(inputKeywords)
    setShowSuggestions(inputKeywords.length > 0)
    setSelectedIndex(-1)
  }

  const handleSuggestionClick = useCallback((suggestion) => {
    const { category, brand, model } = suggestion
    navigate(`/reparacion-electrodomesticos?category=${encodeURIComponent(category || '')}&brand=${encodeURIComponent(brand || '')}&model=${encodeURIComponent(model || '')}`)
    setSearchTerm('')
    setShowSuggestions(false)
    inputRef.current.focus()
  }, [navigate])

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault()
      setSelectedIndex(prevIndex => {
        const newIndex = event.key === 'ArrowUp' ? (prevIndex > 0 ? prevIndex - 1 : filteredItems.length - 1)
          : (prevIndex < filteredItems.length - 1 ? prevIndex + 1 : 0)
        return newIndex
      })
    } else if (event.key === 'Enter' && selectedIndex >= 0 && selectedIndex < filteredItems.length) {
      handleSuggestionClick(filteredItems[selectedIndex])
    }
  }, [selectedIndex, filteredItems, handleSuggestionClick])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  const handleScroll = useCallback(_.throttle(() => {
    const elements = document.querySelectorAll('.animated-element')
    elements.forEach(element => {
      const rect = element.getBoundingClientRect()
      if (rect.top <= window.innerHeight * 0.75) {
        element.classList.add('visible')
      } else if (rect.top < window.innerHeight) {
        element.classList.remove('visible')
      }
    })
  }, 200), [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <div>
      <MiniBanner/>
      <div className="reparation-container">
        <section className="section-reparation">
          <div className='reparation-top'>
            <h1>Reparación de Electrodomésticos</h1>
            <h3>
              <ReactTyped strings={["¿Se rompió tu equipo? Búscalo"]} typeSpeed={50} />
            </h3>
          </div>
          <div className="reparation-bottom">
            <input
              type="text"
              placeholder="Buscar por categoría, marca o modelo"
              value={searchTerm}
              onChange={handleSearchChange}
              onFocus={() => searchTerm && setShowSuggestions(true)}
              ref={inputRef}
            />
            {showSuggestions && (
              <div className="suggestions-container">
                {filteredItems.map((item, index) => (
                  <div
                    key={index}
                    className={`suggestion-item ${index === selectedIndex ? 'selected' : ''}`}
                    onClick={() => handleSuggestionClick(item)}
                  >
                    {`${item.category} ${item.brand} ${item.model}`}
                  </div>
                ))}
              </div>
            )}
            <QuoteButton text="Cotizar Ahora!" onClick={() => handleSuggestionClick(filteredItems[selectedIndex] || {})} />
          </div>
        </section>
      </div>
      <section className='features-container'>
        <ul className='features-list'>
          <li className='feature'>
            <FontAwesomeIcon icon={faCircleCheck} className='feature-icon' />
            <div className='feature-text'>
              <span className='feature-light'>Envios en</span>
              <span className='feature-bold'>Todo el país</span>
            </div>
          </li>
          <li className='feature'>
            <FontAwesomeIcon icon={faCircleCheck} className='feature-icon' />
            <div className='feature-text'>
              <span className='feature-light'>Cuotas</span>
              <span className='feature-bold'>Sin interés</span>
            </div>
          </li>
          <li className='feature'>
            <FontAwesomeIcon icon={faCircleCheck} className='feature-icon' />
            <div className='feature-text'>
              <span className='feature-light'>Garantía</span>
              <span className='feature-bold'>Por 30 días</span>
            </div>
          </li>
          <li className='feature'>
            <FontAwesomeIcon icon={faCircleCheck} className='feature-icon' />
            <div className='feature-text'>
              <span className='feature-light'>Diagnóstico</span>
              <span className='feature-bold'>Gratis</span>
            </div>
          </li>
        </ul>
      </section>
      <article className='services-container'>
        <h2>Nuestros Servicios</h2>
        <section className='services-card-container'>
          <div className='service-item'>
            <FontAwesomeIcon icon={faStore} size="3x" className='service-icon'/>
            <h3>Visítanos</h3>
            <p>Trae tu electrodoméstico a nuestro local para un servicio más rápido.</p>
          </div>
          <div className='service-item'>
            <FontAwesomeIcon icon={faTruck} size="3x" className='service-icon' />
            <h3>Envialo por Correo</h3>
            <p>Envía tu equipo por correo a nuestra dirección por Andreani o Correo Argentino</p>
          </div>
          <div className='service-item'>
            <FontAwesomeIcon icon={faHome} size="3x" className='service-icon' />
            <h3>Retiro a Domicilio</h3>
            <p>Podemos recoger el equipo en tu domicilio, sujeto a disponibilidad según tu ubicación.
            </p>
          </div>
          <div className='service-item'>
            <FontAwesomeIcon icon={faHandHoldingDollar} size="3x" className='service-icon' />
            <h3>Presupuestos Membretados</h3>
            <p>Presupuestos con Membrete para presentar a quien corresponda.</p>
          </div>
        </section>
      </article>
      <article className='brands-container animated-element vertical-animation'>
                <h2>Trabajamos con todas las marcas</h2>
                <h3>Las mejores marcas a tu servicio</h3>
                <section className='brands-logo-container'>
                    {brandLogos.map((logo, index) => (
                        <div key={index} className='brand-logo-item'>
                            <img src={logo.src} alt={logo.alt} className='brand-logo' loading="lazy"/>
                        </div>
                    ))}
                </section>
                <Link to="/reparacion-electrodomesticos">
                    <QuoteButton text="Cotizar Ahora" />
                </Link>
            </article>
            <section className='reviews-section animated-element horizontal-animation'>
                <div className='title-review-container'>
                    <h2>Opiniones de Nuestros Clientes</h2>
                </div>
                <div className='review-location'>
                    <h2>
                        <span className='icon'></span>
                    </h2>
                    <p>Av. Vicente López 770 B1878, Quilmes, Provincia de Buenos Aires</p>
                </div>
                <p className='rating'>
                    <span className='rating-number'>4.7</span>
                    <span className='stars-container'>
                        <span className='star'></span>
                        <span className='star'></span>
                        <span className='star'></span>
                        <span className='star'></span>
                        <span className='star half'></span>
                    </span>
                    <a href="https://www.google.com/search?q=electrosafe+quilmes&sca_esv=a0e417c138758ffa&hl=es-419&gl=ar&sxsrf=ADLYWIL3yPa3TtrqufPpEYP-tPt-nsSfxQ%3A1718729648775&ei=sLtxZun6LovY1sQPp5eKsAQ&oq=electrosa&gs_lp=Egxnd3Mtd2l6LXNlcnAiCWVsZWN0cm9zYSoCCAAyChAjGIAEGCcYigUyChAjGIAEGCcYigUyExAuGIAEGBQYxwEYhwIYjgUYrwEyBRAAGIAEMgUQABiABDIFEAAYgAQyCxAuGIAEGMcBGK8BMgsQLhiABBjHARivATIFEAAYgAQyBRAAGIAESP0TUABYpQtwAHgBkAEAmAGUAaAB2AeqAQMyLje4AQPIAQD4AQGYAgmgAvMHwgIEECMYJ8ICCxAuGIAEGLEDGIMBwgIREC4YgAQYsQMY0QMYgwEYxwHCAgsQABiABBixAxiDAcICDhAAGIAEGLEDGIMBGIoFwgIIEC4YgAQYsQMYxwEYrwHCAggQABiABBixA8ICFBAuGIAEGLEDGIMBGMcBGI4FGK8BmAMAkgcDMC45oAefeA&sclient=gws-wiz-serp#ip=1&lrd=0x95a3332dc6e1e2eb:0x91e0a93b10ba873,1,,,," target="_blank" rel="noopener noreferrer">115 opiniones</a>
                </p>
                <ul className='review-listing'>
                    {reviews.map(review => (
                        <li key={review.id} className='review-item'>
                            <a href={review.url} target="_blank" rel="noopener noreferrer"><img src={review.profilePic} alt={`${review.name}'s profile`} className='profile-pic' loading="lazy"/></a>
                            <div className='review-name'><a href={review.url} target="_blank" rel="noopener noreferrer">{review.name}</a></div>
                            <div className='review-details'>
                                <div className='review-rating'>
                                    {[...Array(5)].map((_, i) => (
                                        <img
                                            key={i}
                                            src={`/images/${i < review.rating ? 'star' : 'halfstar'}.svg`}
                                            alt={i < review.rating ? 'Star' : 'Half Star'}
                                            className='star-icon'
                                        />
                                    ))}
                                </div>
                                <div className='review-time'>{review.timeAgo}</div>
                            </div>
                            <p className='review-comment'>{review.comment}</p>
                        </li>
                    ))}
                </ul>
                <p className='attribution'><span className='powered-by-google' title="Powered by Google"></span></p>
            </section>
            <div className='whatsapp-float'>
            <a href="https://wa.me/5491139148766?text=Hola,%20me%20comunico%20desde%20la%20web%20de%20Electrosafe%20para%20obtener%20la%20mejor%20cotización." target="_blank" rel="noopener noreferrer">
                  <img src='/images/whatsappLogo.svg' alt='WhatsApp' />
              </a>
          </div>
    </div>
  )
}

export default React.memo(MainContent)
